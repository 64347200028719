import React, { useState, useEffect } from 'react';
import ThreadFileUploader from './ThreadFileUploader';
import { FiArrowUp } from 'react-icons/fi';

interface ThreadMessageInputProps {
    onSendMessage: (message: string) => void;
    file: File | null;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    setFile: (file: File | null) => void;
}

const ThreadMessageInput: React.FC<ThreadMessageInputProps> = ({ onSendMessage, file, textareaRef, fileInputRef, setFile }) => {
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        adjustTextareaHeight();
    }, []);

    const adjustTextareaHeight = () => {
        const lineHeight = 20; // Hauteur d'une ligne
        const maxHeight = 120;

        if (textareaRef.current) {
            // Fixer la hauteur initiale à celle d'une ligne
            textareaRef.current.style.height = `${lineHeight}px`;
            const scrollHeight = textareaRef.current.scrollHeight;

            if (scrollHeight > lineHeight) {
                textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                textareaRef.current.style.overflowY = scrollHeight > maxHeight ? 'auto' : 'hidden';
            } else {
                textareaRef.current.style.height = `${lineHeight}px`;
                textareaRef.current.style.overflowY = 'hidden';
            }
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(e.target.value);
        adjustTextareaHeight();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (newMessage.trim()) {
                onSendMessage(newMessage);
                setNewMessage('');
                if (textareaRef.current) {
                    textareaRef.current.style.height = '20px'; // Reset to original height after sending
                }
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (newMessage.trim()) {
            onSendMessage(newMessage);
            setNewMessage('');
            if (textareaRef.current) {
                textareaRef.current.style.height = '20px'; // Reset to original height after sending
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-4 flex space-x-2"
              style={{
                  flexShrink: 0,
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 10,
                  alignItems: 'flex-end',
              }}
        >
            <div className="flex items-end w-full bg-theme-extra-light rounded-[26px] p-1">
                <ThreadFileUploader file={file} setFile={setFile} fileInputRef={fileInputRef} />
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    className="flex-1 bg-theme-extra-light text-theme resize-none border-none focus:ring-0 focus:border-none rounded-none"
                    placeholder="Écrire un message..."
                    required={!file}
                    style={{
                        padding: '8px',
                        boxSizing: 'border-box',
                        height: '20px', // Hauteur par défaut pour une seule ligne
                        maxHeight: '120px',
                        minHeight: '20px',
                        overflowY: 'hidden', // Cache la barre de défilement jusqu'à ce qu'elle soit nécessaire
                        outline: 'none',
                    }}
                />
                <button
                    type="submit"
                    className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-primaryColor"
                >
                    <FiArrowUp className="text-white text-xl"/>
                </button>
            </div>
        </form>
    );
};

export default ThreadMessageInput;