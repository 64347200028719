import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectById, selectProjectById, deleteProject, selectProjectsLoading } from './projectSlice';
import { fetchCustomerById, selectCustomerById } from '../customer/customerSlice';
import Skeleton from '../../components/Skeleton';
import { AppDispatch, RootState } from '../../store/store';
import { fetchMissionsByProjectId, selectMissionsByProjectId } from './missionSlice';
import {FiFile, FiImage, FiChevronDown, FiChevronUp, FiXCircle, FiCheck, FiRotateCw} from 'react-icons/fi';
import DragScrollContainer from "../../components/DragScrollContainer";
import { formatDate } from '../../utils/dateUtils';
import { formatFileSize } from '../../utils/fileUtils';
import { FiCheckCircle, FiClock, FiAlertCircle } from 'react-icons/fi';

interface ProjectDetailProps {
    onEditProject: (project: any) => void;
}

interface Customer {
    uuid: string;
    companyName: string;
    firstName: string;
    lastName: string;
}

// Composant Timeline pour afficher les missions avec TailwindCSS
const Timeline: React.FC<{ missions: any[] }> = ({ missions }) => {
    // Fonction pour déterminer la couleur du texte du statut
    const getStatusTextColor = (status: string) => {
        switch (status) {
            case 'draft':
                return 'text-gray-400'; // Texte gris pour les missions en Draft
            case 'in_progress':
                return 'text-blue-500'; // Texte bleu pour les missions en cours
            case 'completed':
                return 'text-green-500'; // Texte vert pour les missions terminées
            case 'cancelled':
                return 'text-red-500'; // Texte rouge pour les missions annulées
            default:
                return 'text-gray-200'; // Texte par défaut en gris clair
        }
    };

    // Fonction pour déterminer la couleur du point en fonction du statut
    const getStatusColor = (status: string) => {
        switch (status) {
            case 'draft':
                return 'bg-gray-400'; // Point gris pour les missions en Draft
            case 'in_progress':
                return 'bg-blue-500'; // Point bleu pour les missions en cours
            case 'completed':
                return 'bg-green-500'; // Point vert pour les missions terminées
            case 'cancelled':
                return 'bg-red-500'; // Point rouge pour les missions annulées
            default:
                return 'bg-gray-200'; // Point par défaut en gris clair
        }
    };

    // Fonction pour déterminer l'icône à afficher en fonction du statut
    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'draft':
                return;
            case 'in_progress':
                return <FiRotateCw className="text-black dark:text-white" size="14" />; // Icône en cours pour le statut "in_progress"
            case 'completed':
                return <FiCheck className="text-black dark:text-white" size="14" />; // Icône de validation pour le statut "completed"
            default:
                return <FiClock className="text-black dark:text-white" size="14" />; // Icône par défaut
        }
    };

    return (
        <ol className="relative mt-4 ml-3 border-l border-gray-200 dark:border-gray-700">
            {[...missions].reverse().map((mission) => (
                <li key={mission.uuid} className="mb-10 ml-6">
                    {/* Point de la timeline avec couleur dynamique en fonction du statut */}
                    <span className={`absolute flex items-center justify-center w-6 h-6 border-2 border-theme bg-white dark:bg-darkSecondaryBgColor rounded-full -left-3 ring-8 ring-white dark:ring-darkSecondaryBgColor`}>
                        {/* Icône dynamique en fonction du statut */}
                        {getStatusIcon(mission.status)}
                    </span>
                    {/* Titre et statut sur la même ligne */}
                    <div className="ml-4">
                        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                            Date de fin : {new Date(mission.exceptedEndDate).toLocaleDateString('fr-FR')}
                        </time>
                        <div className="flex items-center mb-2">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                {mission.title}
                            </h3>
                            {/*<div className="flex items-center bg-gray-100 px-2 py-1 ml-3 rounded-lg">*/}
                            {/*    <span className={`w-2 h-2 rounded-full mr-2 ${getStatusColor(mission.status)}`}></span>*/}
                            {/*    <span className={`text-sm font-medium ${getStatusTextColor(mission.status)}`}>*/}
                            {/*        {mission.status === 'draft' ? 'Brouillon' : mission.status === 'in_progress' ? 'En cours' : mission.status === 'completed' ? 'Terminé' : 'Annulé'}*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                            {mission.description}
                        </p>
                        <p className="text-xs text-gray-500">Montant : {mission.amount} €</p>
                    </div>
                </li>
            ))}
        </ol>
    );
};

const ProjectDetail: React.FC<ProjectDetailProps> = ({onEditProject}) => {
    const {uuid} = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const project = useSelector((state: RootState) =>
        uuid ? selectProjectById(state, uuid) : undefined
    );

    const customerUuid = typeof project?.customer === 'string'
        ? project.customer
        : (project?.customer && 'uuid' in project.customer)
            ? (project.customer as Customer).uuid
            : undefined;

    const customer = useSelector((state: RootState) =>
        customerUuid ? selectCustomerById(state, customerUuid) : undefined
    );

    const projectLoading = useSelector(selectProjectsLoading);
    const missions = useSelector((state: RootState) =>
        uuid ? selectMissionsByProjectId(state, uuid) : []
    );

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (uuid && !project) {
            dispatch(fetchProjectById(uuid));
        }
    }, [dispatch, uuid, project]);

    useEffect(() => {
        if (customerUuid && !customer) {
            dispatch(fetchCustomerById(customerUuid));
        }
    }, [dispatch, customerUuid, customer]);

    useEffect(() => {
        if (uuid) {
            dispatch(fetchMissionsByProjectId(uuid));
        }
    }, [dispatch, uuid]);

    const handleDeleteClick = () => {
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (uuid) {
            setDeleting(true);
            await dispatch(deleteProject(uuid));
            setDeleting(false);
            navigate('/projects');
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleEdit = () => {
        if (project) {
            onEditProject(project);
        }
    };

    const [openMissions, setOpenMissions] = useState<string[]>([]); // state to track which missions are open

    const toggleMission = (missionUuid: string) => {
        setOpenMissions(prevOpenMissions =>
            prevOpenMissions.includes(missionUuid)
                ? prevOpenMissions.filter(id => id !== missionUuid)
                : [...prevOpenMissions, missionUuid]
        );
    };

    // Calcul du prix total des missions
    const totalAmount = missions.reduce((total, mission) => total + (mission.amount || 0), 0);

    // Trouver la date la plus éloignée parmi les missions
    const mostDistantDate = missions
        .map(mission => new Date(mission.exceptedEndDate))
        .reduce((latest, date) => (date > latest ? date : latest), new Date(0));

    // Formater la date la plus éloignée (par exemple, au format '20 août 2024')
    const formatDateTest = (date: Date) => {
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    const totalMissions = missions.length;
    const completedMissions = missions.filter(mission => mission.status === 'completed').length;
    const completionPercentage = totalMissions > 0 ? (completedMissions / totalMissions) * 100 : 0;

    if (projectLoading || !project) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    return (
        <div className="flex">
            <div className="w-2/3">
                {/* Banner */}
                <div className="bg-blue-200 text-blue-800 p-14 rounded-lg mb-4">
                    <p className="font-bold"></p>
                </div>

                {/* Status Badge */}
                <span className="bg-[#D4CFFF] rounded-full px-4 py-1 text-sm">
                    {project.status.label}
                </span>

                {/* Title and Description */}
                <h1 className="text-2xl font-bold mt-4 mb-4 text-theme">{project.title}</h1>
                <span className="text-theme text-sm text-theme">
                    Type de projet
                </span>
                <p className="text-base mt-4 text-theme">{project.description}</p>

                {/* Documents */}
                {/*<div className="mt-6">*/}
                {/*    <h2 className="text-xl font-semibold text-theme">Documents</h2>*/}
                {/*    <DragScrollContainer className="flex space-x-4 mt-2">*/}
                {/*        {project.documents && project.documents.length > 0 ? (*/}
                {/*            project.documents.map((document: any) => {*/}
                {/*                return (*/}
                {/*                    <div key={document.uuid}*/}
                {/*                         className="flex bg-theme-light border-theme p-4 rounded border-2 items-center cursor-pointer">*/}
                {/*                        <div className="text-xl mr-4 bg-gray-100 p-4 rounded">*/}
                {/*                            {document.mimeType === "application/pdf" ? <FiFile/> : <FiImage/>}*/}
                {/*                        </div>*/}
                {/*                        <div className="w-full">*/}
                {/*                            <p className="text-base font-medium w-48 truncate text-theme">*/}
                {/*                                {document.originalName}*/}
                {/*                            </p>*/}
                {/*                            <span*/}
                {/*                                className="text-sm text-theme">{formatDate(document.createdAt)} </span>*/}
                {/*                            <span className="text-sm text-theme">{formatFileSize(document.size)}</span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                );*/}
                {/*            })*/}
                {/*        ) : (*/}
                {/*            <p className="text-theme">Aucun document disponible pour ce projet.</p>*/}
                {/*        )}*/}
                {/*    </DragScrollContainer>*/}
                {/*</div>*/}

                <div className="mt-8">
                    <div className="flex space-x-4">
                        <button
                            className={'whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium bg-gray-100 text-black'}
                        >
                            Timeline
                        </button>
                        <button
                            className={'whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium text-gray-500 hover:bg-gray-100'}
                        >
                            Document
                        </button>
                    </div>
                </div>
            </div>

            {/* Right-side Project Information */}
            <div className="w-1/3 p-4 ml-6">
                <h2 className="text-lg font-semibold text-theme">Information du projet</h2>
                <p>
                    <strong className="text-theme">Date de fin</strong>
                    <span className="text-theme" style={{display: 'block'}}>{formatDateTest(mostDistantDate)}</span>
                </p>

                <p className="text-theme">
                    <strong>Client:</strong> {customer ? (
                    <Link to={`/customers/${customer.uuid}`} className="text-blue-500 underline">
                        {customer.companyName} - {customer.firstName} {customer.lastName}
                    </Link>
                ) : 'Chargement...'}
                </p>

                <h2 className="text-lg font-semibold mt-6 text-theme">Status</h2>
                <div className="w-full bg-gray-300 h-2 rounded-full">
                    <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${completionPercentage}%` }}></div>
                </div>

                {/* Total Amount */}
                <div className="mt-6">
                    <h2 className="text-lg font-semibold text-theme">Total</h2>
                    <p className="text-2xl font-bold text-theme">{totalAmount} €</p>
                </div>

                {/* Missions as Timeline */}
                <div className="mt-6">
                    <h2 className="text-xl font-semibold text-theme">Missions Timeline</h2>
                    {missions.length > 0 ? (
                        <Timeline missions={missions}/>
                    ) : (
                        <p className="text-theme">No missions associated with this project.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectDetail;