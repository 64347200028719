import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ProjectList from '../features/project/ProjectList';
import ProjectForm from '../features/project/ProjectForm';
import ProjectDetail from '../features/project/ProjectDetail';
import SidePanel from "../components/SidePanel";
import CustomerForm from '../features/customer/CustomerForm';

const ProjectsPage: React.FC<{ setActionButton: (button: React.ReactNode) => void }> = ({ setActionButton }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Récupérer l'URL actuelle

    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState<any | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // Gérer l'état de chargement

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        customer: '' // Le client sera sélectionné ici
    });

    // Utilisation de useRef pour les formulaires
    const projectFormRef = useRef<any>(null);
    const customerFormRef = useRef<any>(null);

    // Fonction pour ouvrir le side panel pour créer un projet
    const handleCreateProject = () => {
        setIsSidePanelOpen(true);
        setSelectedProject(null);
        setIsEditMode(false);
        setCurrentPageIndex(0);
    };

    // Définir le bouton "Nouveau Projet" dans NavbarTop
    useEffect(() => {
        setActionButton(
            <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                onClick={handleCreateProject} // Ouvrir le SidePanel
            >
                Nouveau Projet
            </button>
        );

        // Nettoyer le bouton lors du démontage
        return () => setActionButton(null);
    }, [setActionButton]);

    // Autres handlers (édition, fermeture du SidePanel, etc.)
    const handleEditProject = (project: any) => {
        setIsSidePanelOpen(true);
        setSelectedProject(project);
        setIsEditMode(true);
        setCurrentPageIndex(0);
    };

    const handleViewDetails = (uuid: string) => {
        navigate(`/projects/${uuid}`);
    };

    const handleCloseSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    const handleCustomerCreated = (customerUuid?: string) => {
        if (customerUuid) {
            setFormData((prevData) => ({
                ...prevData,
                customer: customerUuid
            }));
        }
        setCurrentPageIndex(0); // Remettre à la première page sans fermer le SidePanel
    };

    // Fonction de validation pour chaque page avec gestion du chargement
    const handleValidate = async () => {
        setIsLoading(true); // Active l'état de chargement
        if (currentPageIndex === 0 && projectFormRef.current) {
            await projectFormRef.current.submitForm(); // Validation du formulaire projet
        } else if (currentPageIndex === 1 && customerFormRef.current) {
            await customerFormRef.current.submitForm(); // Validation du formulaire client
        }
        setIsLoading(false); // Désactive l'état de chargement après soumission
    };

    return (
        <div className="p-4 h-full">
            <Routes>
                <Route
                    path="/"
                    element={<ProjectList onEditProject={handleEditProject} onViewDetails={handleViewDetails} />}
                />
                <Route
                    path=":uuid"
                    element={<ProjectDetail onEditProject={handleEditProject} />}
                />
            </Routes>

            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={handleCloseSidePanel}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
                onValidate={handleValidate} // Validation selon la page active
                isLoading={isLoading} // Passer l'état de chargement au SidePanel
            >
                <div data-page="1" data-validation-text="Créer un projet" data-has-navigation="true">
                    <ProjectForm
                        ref={projectFormRef}
                        onSuccess={handleCloseSidePanel}
                        projectData={selectedProject}
                        isEditMode={isEditMode}
                        setFormData={setFormData}
                        formData={formData} // Passer les données du formulaire au ProjectForm
                        isLoading={isLoading} // Passer l'état de chargement au ProjectForm
                        onNext={() => setCurrentPageIndex(1)}
                    />
                </div>

                <div data-page="2" data-validation-text="Créer un client" data-has-navigation="true">
                    <CustomerForm
                        ref={customerFormRef}
                        onSuccess={handleCustomerCreated} // Retourne à la première page et met à jour le client sélectionné
                        isLoading={isLoading} // Passer l'état de chargement au CustomerForm
                    />
                </div>
            </SidePanel>
        </div>
    );
};

export default ProjectsPage;