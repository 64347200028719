import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { getUserInfo } from '../api/authService';
import { getCompanyInfo } from '../api/companyService';
import { setUser, setLoading, selectLoading } from '../features/auth/authSlice';
import { setCompany } from '../features/company/companySlice';
import Skeleton from '../components/Skeleton';
import { useTranslation } from 'react-i18next';

const DashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const company = useSelector((state: RootState) => state.company.company);
    const user = useSelector((state: RootState) => state.auth.user);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        const fetchData = async () => {
            if (!user) {
                dispatch(setLoading(true));
                try {
                    const userResponse = await getUserInfo();
                    dispatch(setUser(userResponse.data));

                    if (userResponse.data.company) {
                        const companyResponse = await getCompanyInfo(userResponse.data.company.uuid);
                        dispatch(setCompany(companyResponse.data));
                    } else {
                        dispatch(setCompany(null));
                    }
                } catch (error) {
                    console.error(t('errors.fetchingUserInfo'), error);
                } finally {
                    dispatch(setLoading(false));
                }
            }
        };

        fetchData();
    }, [user, dispatch, t]);

    const isLoading = loading || !company;

    return (
        <div className="flex h-full">
            <div className="flex-1">
                <div className="pl-6 p-4">
                    <h2 className="text-theme text-xl font-bold mb-4 flex items-center ease-in-out">{t('dashboard.hello')}, {isLoading ? <Skeleton width="15%" height="20px"/> : user?.firstName}</h2>
                </div>
                <div className="pl-6 p-4">
                    <h2 className="text-theme text-xl font-bold mb-4 ease-in-out">{t('dashboard.statistics')}</h2>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.profits')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px"/> : <p className="text-theme">€ 10,000</p>}
                        </div>
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.projects')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px"/> : <p className="text-theme">{user?.projectsCount}</p>}
                        </div>
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.customers')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px"/> : <p className="text-theme">{user?.customersCount}</p>}
                        </div>
                    </div>
                    <div className="mt-8 p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                        <h3 className="text-theme text-lg font-bold">{t('dashboard.userInfo')}</h3>
                        {isLoading ? (
                            <>
                                <Skeleton width="100%" height="20px"/>
                                <Skeleton width="100%" height="20px"/>
                                <Skeleton width="100%" height="20px"/>
                                <Skeleton width="100%" height="20px"/>
                            </>
                        ) : (
                            <>
                                <p className="text-theme"><strong>{t('user.email')}:</strong> {user?.email}</p>
                                <p className="text-theme"><strong>{t('user.firstName')}:</strong> {user?.firstName}</p>
                                <p className="text-theme"><strong>{t('user.lastName')}:</strong> {user?.lastName}</p>
                                <p className="text-theme"><strong>{t('user.lastLogin')}:</strong> {user?.lastLogin}</p>
                            </>
                        )}
                        {company && (
                            <>
                                <h3 className="text-theme text-lg font-bold">{t('dashboard.companyInfo')}</h3>
                                {isLoading ? (
                                    <>
                                        <Skeleton width="100%" height="20px"/>
                                        <Skeleton width="100%" height="20px"/>
                                        <Skeleton width="100%" height="20px"/>
                                    </>
                                ) : (
                                    <>
                                        <p className="text-theme"><strong>{t('company.legalName')}:</strong> {company.legalName}</p>
                                        <p className="text-theme"><strong>{t('company.commercialName')}:</strong> {company.commercialName}</p>
                                        <p className="text-theme"><strong>{t('company.siret')}:</strong> {company.siret}</p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;