import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { useTheme } from './hooks/useTheme';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import DashboardPage from './views/DashboardPage';
import Customers from './views/CustomersPage';
import ProjectsPage from './views/ProjectsPage';
import MessagingPage from './views/MessagingPage';
import SettingsPage from './views/SettingsPage';
import CreateCompanyForm from './features/company/CreateCompanyForm';
import DocumentPage from './views/DocumentPage';
import PrivateRoute from './components/routes/PrivateRoute';
import CompanyCheckRoute from './components/routes/CompanyCheckRoute';
import NavbarTop from './components/NavbarTop';
import NavbarSide from './components/NavbarSide';
import './i18n/i18n';
import { useMercure } from './hooks/useMercure';

const App: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const location = useLocation();
    const { theme, toggleTheme } = useTheme();
    const [actionButton, setActionButton] = useState<React.ReactNode>(null); // State pour gérer le bouton

    useMercure();

    const hideNavbar = location.pathname === '/create-company';

    return (
        <div className="flex h-screen bg-theme-light overflow-hidden">
            {isAuthenticated && !hideNavbar && <NavbarSide theme={theme} toggleTheme={toggleTheme} />}
            <div className="flex-1 p-4 pl-0 flex flex-col overflow-hidden">
                <div className="bg-theme border-theme border-2 h-full rounded-lg flex flex-col overflow-hidden">
                    {isAuthenticated && !hideNavbar && <NavbarTop actionButton={actionButton} />} {/* Passer le bouton */}
                    <div className="flex-1 flex flex-col overflow-y-auto">
                        <Routes>
                            <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
                            <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} />
                            <Route path="/signup" element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/create-company" element={<CreateCompanyForm />} />
                                <Route element={<CompanyCheckRoute />}>
                                    <Route path="/dashboard" element={<DashboardPage />} />
                                    <Route path="/customers/*" element={<Customers setActionButton={setActionButton} />} />
                                    <Route path="/projects/*" element={<ProjectsPage setActionButton={setActionButton} />} />
                                    <Route path="/documents/*" element={<DocumentPage />} />
                                    <Route path="/messaging/*" element={<MessagingPage />} />
                                    <Route path="/settings" element={<SettingsPage />} />
                                </Route>
                            </Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;