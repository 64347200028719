import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { getMissionsByProject } from '../../api/projectService';

interface Mission {
    uuid: string;
    title: string;
    description: string;
    exceptedEndDate: string;
    amount: number;
    status: string;
}

interface MissionsState {
    missions: Record<string, Mission[]>;
    loading: boolean;
    error: string | null;
}

const initialState: MissionsState = {
    missions: {},
    loading: false,
    error: null,
};

export const fetchMissionsByProjectId = createAsyncThunk(
    'missions/fetchMissionsByProjectId',
    async (projectUuid: string, { rejectWithValue }) => {
        try {
            const response = await getMissionsByProject(projectUuid);
            return { projectUuid, missions: response.items }; // Assure-toi que `items` est bien le tableau des missions
        } catch {
            return rejectWithValue('Erreur lors de la récupération des missions');
        }
    }
);

const missionSlice = createSlice({
    name: 'missions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMissionsByProjectId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMissionsByProjectId.fulfilled, (state, action: PayloadAction<{ projectUuid: string; missions: Mission[] }>) => {
                state.missions[action.payload.projectUuid] = action.payload.missions;
                state.loading = false;
            })
            .addCase(fetchMissionsByProjectId.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
            });
    }
});

export const selectMissionsByProjectId = (state: RootState, projectUuid: string) =>
    state.missions.missions[projectUuid] || [];

export default missionSlice.reducer;