import React from 'react';
import { FiPaperclip } from 'react-icons/fi';

interface ThreadFileUploaderProps {
    file: File | null;
    setFile: (file: File | null) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
}

const ThreadFileUploader: React.FC<ThreadFileUploaderProps> = ({ file, setFile, fileInputRef }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setFile(selectedFile || null);
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="relative flex items-center justify-center">
            <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden" // Masque complètement l'input
            />
            <div
                onClick={handleClick}
                className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer transform rotate-90"
            >
                <FiPaperclip className="text-theme text-xl transform rotate-45" />
            </div>
        </div>
    );
};

export default ThreadFileUploader;