import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
    createProject as createProjectAPI,
    deleteProject as apiDeleteProject,
    getAllProjects,
    getProjectInfo as apiGetProjectInfo,
    updateProject as apiUpdateProject,
    createMission as apiCreateMission
} from '../../api/projectService';

interface ProjectDocument {
    uuid: string;
    createdAt: string;
    updatedAt: string;
}

interface Project {
    uuid: string;
    title: string;
    status: Status;
    description: string;
    customer: string;
    documents: ProjectDocument[];  // Ajoutez cette ligne pour inclure les documents dans le projet
}

interface Status {
    label: string;
    value: string;
}

export interface NewProject {
    title: string;
    description: string;
    customer: string;
}

interface ProjectsState {
    projects: Project[];
    loading: boolean;
    error: string | null;
}

const initialState: ProjectsState = {
    projects: [],
    loading: false,
    error: null,
};

interface Mission {
    title: string;
    description: string;
    exceptedEndDate: string;
    amount: number;
}

// Helper function to handle errors
const handleRejected = <T>(state: ProjectsState, action: PayloadAction<unknown, string, T, SerializedError>) => {
    state.loading = false;
    state.error = action.error.message ?? 'Une erreur est survenue';
};

export const createMission = createAsyncThunk(
    'projects/createMission',
    async ({ projectUuid, missionData }: { projectUuid: string; missionData: Mission }, { rejectWithValue }) => {
        try {
            return await apiCreateMission(projectUuid, missionData);
        } catch {
            return rejectWithValue('Erreur lors de la création de la mission');
        }
    }
);

// Thunks
export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (_, { rejectWithValue }) => {
    try {
        const { items } = await getAllProjects();
        return items;
    } catch {
        return rejectWithValue('Échec de la récupération des projets');
    }
});

export const fetchProjectById = createAsyncThunk('projects/fetchProjectById', async (uuid: string, { rejectWithValue }) => {
    try {
        return await apiGetProjectInfo(uuid);
    } catch {
        return rejectWithValue('Échec de la récupération du projet');
    }
});

export const createProject = createAsyncThunk('projects/createProject', async (projectData: NewProject, { rejectWithValue }) => {
    try {
        return await createProjectAPI(projectData);
    } catch {
        return rejectWithValue('Erreur lors de la création du projet');
    }
});

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async ({ uuid, projectData }: { uuid: string; projectData: Partial<Omit<Project, 'uuid'>> }) => {
        return await apiUpdateProject(uuid, projectData);
    }
);

export const deleteProject = createAsyncThunk('projects/deleteProject', async (uuid: string, { rejectWithValue }) => {
    try {
        await apiDeleteProject(uuid);
        return uuid;
    } catch {
        return rejectWithValue('Erreur lors de la suppression du projet');
    }
});

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjects.fulfilled, (state, action: PayloadAction<Project[]>) => {
                state.projects = action.payload;
                state.loading = false;
            })
            .addCase(fetchProjects.rejected, handleRejected)
            .addCase(fetchProjectById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjectById.fulfilled, (state, action: PayloadAction<Project>) => {
                const index = state.projects.findIndex(project => project.uuid === action.payload.uuid);
                if (index >= 0) {
                    state.projects[index] = action.payload;
                } else {
                    state.projects.push(action.payload);
                }
                state.loading = false;
            })
            .addCase(fetchProjectById.rejected, handleRejected)
            .addCase(createProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.projects.push(action.payload);
            })
            .addCase(createProject.rejected, handleRejected)
            .addCase(updateProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.projects = state.projects.map(project =>
                    project.uuid === action.payload.uuid ? action.payload : project
                );
            })
            .addCase(deleteProject.fulfilled, (state, action: PayloadAction<string>) => {
                state.projects = state.projects.filter(project => project.uuid !== action.payload);
            });
    }
});

export const selectProjects = (state: RootState) => state.projects.projects;
export const selectProjectsLoading = (state: RootState) => state.projects.loading;
export const selectProjectsError = (state: RootState) => state.projects.error;

export const selectProjectById = (state: RootState, uuid: string) =>
    state.projects.projects.find((project: Project) => project.uuid === uuid);

export default projectSlice.reducer;