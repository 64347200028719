import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, deleteProject, selectProjects, selectProjectsLoading } from './projectSlice';
import { AppDispatch } from '../../store/store';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import { FiMoreVertical, FiList, FiGrid } from 'react-icons/fi'; // Import des icônes

interface ProjectListProps {
    onEditProject: (project: any) => void;
    onViewDetails: (uuid: string) => void;
}

const ProjectList: React.FC<ProjectListProps> = ({ onEditProject, onViewDetails }) => {
    const dispatch = useDispatch<AppDispatch>();

    const projects = useSelector(selectProjects);
    const loading = useSelector(selectProjectsLoading);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState<any | null>(null);
    const [deleting, setDeleting] = useState(false);
    const [viewType, setViewType] = useState<'list' | 'kanban'>('list');
    const [hoveredProject, setHoveredProject] = useState<string | null>(null);

    useEffect(() => {
        if (projects.length === 0) {
            dispatch(fetchProjects());
        }
    }, [dispatch, projects.length]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.hover-menu')) {
                setHoveredProject(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDeleteClick = (project: any) => {
        setProjectToDelete(project);
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (projectToDelete) {
            setDeleting(true);
            await dispatch(deleteProject(projectToDelete.uuid));
            setDeleting(false);
            setShowConfirmModal(false);
            setProjectToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
        setProjectToDelete(null);
    };

    return (
        <div>
            {/* Boutons radio stylisés pour sélectionner la vue */}
            <div className="mb-2 flex justify-start items-center">
                <div className="inline-flex rounded-md">
                    <button
                        type="button"
                        className={`px-4 py-2 rounded-l-md border border-gray-300 text-sm ${
                            viewType === 'list' ? 'bg-white shadow' : 'bg-gray-100'
                        }`}
                        onClick={() => setViewType('list')}
                    >
                        <FiList className={`text-base ${viewType === 'list' ? 'text-black' : 'text-gray-500'}`} />
                    </button>
                    <button
                        type="button"
                        className={`px-4 py-2 rounded-r-md border border-gray-300 text-sm ${
                            viewType === 'kanban' ? 'bg-white shadow' : 'bg-gray-100'
                        }`}
                        onClick={() => setViewType('kanban')}
                    >
                        <FiGrid className={`text-base ${viewType === 'kanban' ? 'text-black' : 'text-gray-500'}`} />
                    </button>
                </div>
            </div>

            {loading ? (
                <div className="grid grid-cols-3 gap-4">
                    {[...Array(6)].map((_, i) => (
                        <div key={i} className="bg-white p-4 rounded shadow">
                            <Skeleton height="20px" width="70%" />
                            <Skeleton height="20px" width="50%" />
                            <Skeleton height="30px" width="100%" />
                        </div>
                    ))}
                </div>
            ) : (
                projects.length === 0 ? (
                    <p>Aucun projet trouvé.</p>
                ) : (
                    viewType === 'list' ? (
                        // Affichage en mode liste
                        <div className="space-y-4">
                            {projects.map((project: any) => (
                                <div
                                    key={project.uuid}
                                    className="bg-white p-4 rounded border-theme border-2 flex justify-between items-center cursor-pointer"
                                    onClick={() => onViewDetails(project.uuid)}
                                >
                                    <div>
                                        <h3 className="text-base font-medium">{project.title}</h3>
                                        <p>{project.customer.uuid}</p>
                                    </div>

                                    {/* Bouton avec trois points pour afficher les actions */}
                                    <div className="relative hover-menu">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setHoveredProject(hoveredProject === project.uuid ? null : project.uuid);
                                            }}
                                            className="w-8 h-8 flex justify-center items-center rounded-full bg-gray-200 text-gray-600 hover:text-gray-900"
                                        >
                                            <FiMoreVertical />
                                        </button>

                                        {hoveredProject === project.uuid && (
                                            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-md z-10 hover-menu">
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditProject(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        // Affichage en mode Kanban
                        <div className="grid grid-cols-3 gap-4">
                            {projects.map((project: any) => (
                                <div
                                    key={project.uuid}
                                    className="bg-white p-4 rounded border-theme border-2 flex justify-between items-center cursor-pointer"
                                    onClick={() => onViewDetails(project.uuid)}
                                >
                                    <div>
                                        <h3 className="text-base font-medium">{project.title}</h3>
                                        <p>{project.customer.uuid}</p>
                                    </div>

                                    {/* Bouton avec trois points pour afficher les actions */}
                                    <div className="relative hover-menu">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setHoveredProject(hoveredProject === project.uuid ? null : project.uuid);
                                            }}
                                            className="w-8 h-8 flex justify-center items-center rounded-full bg-gray-200 text-gray-600 hover:text-gray-900"
                                        >
                                            <FiMoreVertical />
                                        </button>

                                        {hoveredProject === project.uuid && (
                                            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-md z-10 hover-menu">
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditProject(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(project);
                                                        setHoveredProject(null);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                )
            )}

            {/* Modal de confirmation de suppression */}
            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le projet suivant ?</p>
                {projectToDelete && (
                    <p className="font-bold">{projectToDelete.title}</p>
                )}
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete}
                            disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                            onClick={confirmDelete} disabled={deleting}>
                        {deleting ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : 'Supprimer'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ProjectList;