import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocuments, fetchDocument, removeDocument, selectDocuments, selectDocumentsLoading } from './documentSlice';
import { AppDispatch } from '../../store/store';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import { formatFileSize } from '../../utils/fileUtils';
import { formatDate } from '../../utils/dateUtils';
import {FiArrowUp, FiArrowDown, FiFile, FiImage} from 'react-icons/fi';
import DragScrollContainer from "../../components/DragScrollContainer";

interface DocumentListProps {
    onEditDocument?: (document: any) => void;
    onViewDetails?: (uuid: string) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({ onEditDocument, onViewDetails }) => {
    const dispatch = useDispatch<AppDispatch>();

    const documents = useSelector(selectDocuments) || [];
    const loading = useSelector(selectDocumentsLoading);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState<any | null>(null);
    const [deleting, setDeleting] = useState(false);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);


    // Fonction pour trier les documents en fonction de la colonne sélectionnée
    const sortedDocuments = [...documents].sort((a, b) => {
        if (sortConfig !== null) {
            const { key, direction } = sortConfig;
            let aValue = a[key];
            let bValue = b[key];

            // Si on trie la date ou la taille, on utilise un comparateur numérique ou de date
            if (key === 'createdAt') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            if (key === 'size') {
                aValue = parseFloat(aValue);
                bValue = parseFloat(bValue);
            }

            if (aValue < bValue) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });

    useEffect(() => {
        if (documents.length === 0) {
            dispatch(fetchDocuments());
        }
    }, [dispatch, documents.length]);

    const handleViewDetails = async (uuid: string) => {
        if (onViewDetails) {
            onViewDetails(uuid);  // Trigger the callback if provided
        } else {
            // Optionally, you could redirect to a details page if needed:
            // window.location.href = `/documents/${uuid}`;
        }
    };

    const handleDeleteClick = (document: any) => {
        setDocumentToDelete(document);
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (documentToDelete) {
            setDeleting(true);
            await dispatch(removeDocument(documentToDelete.uuid));
            setDeleting(false);
            setShowConfirmModal(false);
            setDocumentToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
        setDocumentToDelete(null);
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    // Fonction pour gérer le clic sur les en-têtes de colonnes
    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key: string) => {
        if (sortConfig && sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? (
                <FiArrowUp className="w-3 h-3 text-theme inline-block" />
            ) : (
                <FiArrowDown className="w-3 h-3 text-theme inline-block" />
            );
        }

        return <FiArrowDown className="w-3 h-3 text-theme inline-block" />;
    };

    const groupedDocuments = documents.reduce((acc: any, document: any) => {
        const projectUuid = document.project.uuid; // Assurez-vous que chaque document a un champ project.uuid
        if (!acc[projectUuid]) {
            acc[projectUuid] = {
                project: document.project, // Inclut les informations du projet
                totalSize: 0, // Initialiser la taille totale à 0
                fileCount: 0, // Initialiser le nombre de fichiers à 0
            };
        }
        acc[projectUuid].totalSize += document.size; // Ajouter la taille du document à la taille totale du projet
        acc[projectUuid].fileCount += 1; // Incrémenter le compteur de fichiers
        return acc;
    }, {});

    return (
        <div>
            {loading ? (
                <div className="grid grid-cols-3 gap-4">
                    {[...Array(6)].map((_, i) => (
                        <div key={i} className="bg-white p-4 rounded shadow">
                            <Skeleton height="20px" width="70%" />
                            <Skeleton height="20px" width="50%" />
                            <Skeleton height="30px" width="100%" />
                        </div>
                    ))}
                </div>
            ) : (
                documents.length === 0 ? (
                    <p>Aucun document trouvé.</p>
                ) : (
                    <div>
                        <p className="block mb-4 text-base font-medium text-theme">
                            Projets
                        </p>
                        <DragScrollContainer className="mb-6">
                            {Object.keys(groupedDocuments).map((projectUuid) => (
                                <div key={projectUuid} className="inline-block bg-theme-light p-4 rounded mr-4 cursor-pointer">
                                    <p className="text-base font-medium w-48 truncate">
                                        {groupedDocuments[projectUuid].project.uuid}
                                    </p>
                                    <span className="text-sm">{groupedDocuments[projectUuid].fileCount} fichiers </span><span
                                    className="text-sm">{formatFileSize(groupedDocuments[projectUuid].totalSize)}</span>
                                </div>
                            ))}
                        </DragScrollContainer>
                        <p className="block mb-4 text-base font-medium text-theme">
                            Récents
                        </p>
                        <DragScrollContainer className="flex mb-6">
                            {documents.slice(0, 8).map((document: any) => (
                                <div key={document.uuid}
                                     className="flex bg-white p-4 rounded border-theme border-2 cursor-pointer mr-4">
                                    <div className="text-xl mr-4 bg-gray-100 p-4 rounded">
                                        {document.mimeType === "application/pdf" ? <FiFile/> : <FiImage/>}
                                    </div>
                                    <div className="w-full">
                                        <p className="text-base font-medium w-48 truncate">
                                            {document.originalName}
                                        </p>
                                        <span className="text-sm">{formatDate(document.createdAt)} </span>
                                        <span className="text-sm">{formatFileSize(document.size)}</span>
                                        {/*<Link to={document.publicUrl} target="_blank"*/}
                                        {/*      className="text-base">*/}
                                        {/*    test link*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            ))}
                        </DragScrollContainer>
                        <p className="block mb-4 text-base font-medium text-theme">
                            Tous les fichiers
                        </p>
                        <div className="border border-gray-200 rounded-lg p-2 pb-0">
                            <table className="min-w-full">
                                <thead>
                                <tr className="bg-gray-100">
                                    <th
                                        className="px-2 py-1 cursor-pointer text-left font-normal rounded-tl-md rounded-bl-md"
                                    >
                                        <div className="flex items-center" onClick={() => requestSort('originalName')}>
                                            <span className="mr-2 text-sm">Titre</span><span
                                            className="align-middle">{getSortIcon('originalName')}</span>
                                        </div>
                                    </th>
                                    <th
                                        className="px-2 py-1 cursor-pointer text-left font-normal"
                                    >
                                        <div className="flex items-center" onClick={() => requestSort('createdAt')}>
                                            <span className="mr-2 text-sm">Date d'ajout</span><span
                                            className="align-middle">{getSortIcon('createdAt')}</span>
                                        </div>
                                    </th>
                                    <th
                                        className="px-2 py-1 cursor-pointer text-left font-normal rounded-tr-md rounded-br-md"
                                    >
                                        <div className="flex items-center" onClick={() => requestSort('size')}>
                                            <span className="mr-2 text-sm">Taille</span><span
                                            className="align-middle">{getSortIcon('size')}</span>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {sortedDocuments.map((document: any, index: number) => (
                                    <tr
                                        key={document.uuid}
                                        className="hover:bg-gray-100" // Applique le changement de fond au survol sur toute la ligne
                                    >
                                        <td
                                            className={`px-2 py-4 ${
                                                index !== sortedDocuments.length - 1 ? 'border-b border-gray-200' : ''
                                            }`}
                                        >
                                            {document.originalName}
                                        </td>
                                        <td
                                            className={`px-2 py-2 ${
                                                index !== sortedDocuments.length - 1 ? 'border-b border-gray-200' : ''
                                            }`}
                                        >
                                            {formatDate(document.createdAt)}
                                        </td>
                                        <td
                                            className={`px-2 py-2 ${
                                                index !== sortedDocuments.length - 1 ? 'border-b border-gray-200' : ''
                                            }`}
                                        >
                                            {formatFileSize(document.size)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            )}

            {/* Modal de confirmation de suppression */}
            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le document suivant ?</p>
                {documentToDelete && (
                    <p className="font-bold">{documentToDelete.displayText}</p>
                )}
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete}
                            disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                            onClick={confirmDelete} disabled={deleting}>
                        {deleting ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : 'Supprimer'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default DocumentList;