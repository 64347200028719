import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateCompanyInfo } from '../../../api/companyService';
import { setCompany } from '../../company/companySlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';

const CompanyInformationForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user, company } = useSelector((state: RootState) => ({
        user: state.auth.user,
        company: state.company.company,
    }));

    const [formData, setFormData] = React.useState({
        legalName: '',
        commercialName: '',
        siret: '',
    });

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (company) {
            setFormData({
                legalName: company.legalName,
                commercialName: company.commercialName,
                siret: company.siret,
            });
            setLoading(false);
        }
    }, [company]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (user && user.companyUUID) {
                const response = await updateCompanyInfo(user.companyUUID, formData);
                dispatch(setCompany(response.data));
                setMessage(t('settings.updateSuccess'));
            }
        } catch (error) {
            console.error(t('settings.updateError'), error);
            setMessage(t('settings.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.companyInformation')}
            </label>
            <div className="space-y-6">
                {loading ? (
                    <>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                    </>
                ) : (
                    <>
                    <div>
                        <input
                            name="legalName"
                            type="text"
                            required
                            className="block w-full px-3 py-2 border rounded-md"
                            placeholder={t('company.legalName')}
                            value={formData.legalName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            name="commercialName"
                            type="text"
                            required
                            className="block w-full px-3 py-2 border rounded-md"
                            placeholder={t('company.commercialName')}
                            value={formData.commercialName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            name="siret"
                            type="text"
                            required
                            className="block w-full px-3 py-2 border rounded-md"
                            placeholder={t('company.siret')}
                            value={formData.siret}
                            onChange={handleChange}
                        />
                    </div>
                    </>
                    )}
                <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    {t('settings.update')}
                </button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
);
};

export default CompanyInformationForm;